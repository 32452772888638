import React, { useState, useEffect } from 'react';
import '../assets/works.css';
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import Html5Ads from '../assets/html5ads.webp';
import CBV from '../assets/cbv.webp';
import CbvApp from '../assets/cbv-app.webp';
import WPtoMC from '../assets/WPtoMC.webp';
import MarkComplete from '../assets/MarkComplete.webp';
import MSF from '../assets/MSF_impact.webp';

import {
  FaInfoCircle,
  FaTimes
} from "react-icons/fa";



const WorksInfo = ({ title, link_text, link, description, image }) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    if (window.innerWidth <= 768) {
      setIsOpen(!isOpen);
    }
  };

  useEffect(() => {
    const elements = document.querySelectorAll('.box-container');

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('visible');
          } else {
            entry.target.classList.remove('visible');
          }
        });
      },
      {
        threshold: 0.2, // Adjust the threshold as needed
      }
    );

    elements.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      elements.forEach((element) => {
        observer.unobserve(element);
      });
    };
  }, []);

  return (

    <div className="box-container">
      <div className={`box ${isOpen ? 'open visible' : ''}`} onClick={handleClick}>
      <LazyLoadComponent>
        {React.cloneElement(image, { height:"582px" , width:"332px"})} { }
        </LazyLoadComponent>
        <div className="box-content relative ">
          <h2 className="text-lg mb-0 lg:mb-2 text-center">{title}</h2>
          <div className='op_cl z-[10] absolute bottom-[10px] text-white text-lg'>
            {!isOpen ? <FaInfoCircle /> : <FaTimes />}
          </div>

          <p className="content p-0 lg:p-10  text-center lg:text-right" >{description}
            <a className='block underline pt-[10px] md:pt-[20px] w-[80%] md:w-[100%] m-auto  md:m-0' href={link} target="_blank" rel="noindex, nofollow">{link_text}</a></p>
        </div>

      </div>
    </div>
   
  )
};

const worksInfo = [
  {
    id: 1, // Added unique id
    title: 'CBV Website',
    link_text: 'VIEW PROJECT',
    link: 'https://cbvinstitute.com/',
    description: "CBV Institute sets standards, educational requirements, and ethical guidelines for Chartered Business Valuators (CBVs) in Canada. The WordPress website, enhanced with custom PHP.",
    image: <img src={CBV} alt="CBV Screenshot" />, // Corrected image component
  },
  {
    id: 2, // Added unique id
    title: 'CBV APP',
    link_text: 'VIEW PROJECT',
    link: 'https://apps.apple.com/ca/app/cbv-institute/id1447654615',
    description: "CBV Institute's mobile app for iOS and Android is built using Cordova and a custom API with the CBV WordPress site. The app offers seamless access to resources and information on the go.",
    image: <img src={CbvApp} alt="CBV Screenshot" />, // Corrected image component
  },
  {
    id: 3, // Added unique id
    title: 'Post to Mailchip',
    link_text: 'SEE ON GITHUB',
    link: 'https://github.com/jeffmbalicki/wp-post-to-mailchimp',
    description: "Created a WordPress plugin that allows users to post content directly to Mailchimp using its API for authentication and posting. This plugin is perfect for bloggers and content creators sharing content directly with their subscribers.",
    image: <img src={WPtoMC} alt="WP to MC" />, // Corrected image component
  },
  {
    id: 4, // Added unique id
    title: 'Learndash mark as complete',
    link_text: 'SEE ON GITHUB',
    link: 'https://github.com/jeffmbalicki/ld-make-as-complete',
    description: "Created a Wordpress plugin that allows users to mark lessons as complete for live streams and webinars. The plugin uses the Learndash API to track user progress update lesson status, and show if the user is online.",
    image: <img src={MarkComplete} alt="Learndash mark as complete" />, // Corrected image component
  },
  {
    id: 5, // Added unique id
    title: 'HTML5 Animated Banner Ads',
    link: 'ads.html',
    link_text: 'VIEW PROJECT',
    description: "Created HTML5 banner ads for Google using GSAP and SVGs for smooth animations. GSAP's powerful animation capabilities combined with SVGs ensure visually appealing ads that perform well and meet Google's ad requirements.",
    image: <img src={Html5Ads} alt="HTML5 Animated Banner Ads" />, // Corrected image component
  },
  {
    id: 6, // Added unique id
    title: 'MSF Canada\'s Annual Report',
    link_text: 'VIEW PROJECT',
    link: 'https://impact.doctorswithoutborders.ca/',
    description: "Created MSF Canada's Annual Report website. Built using WordPress, Infinite Scroll JS and Isotope JS to give the feel and look of a magazine. The website is fully responsive and optimized for mobile devices.",
    image: <img src={MSF} alt="MSF Canada's Annual Report" />, // Corrected image component
  },
];

const Works = () => {
  return (
    <div
      name="works"
      id="works"
      className="w-full overflow-y-hidden bg-white text-[#4d4d4d] min-h-[1026px] pt-[40px] scroll-pl-[-300px] mx-auto"
    >
      <h2 className="text-center text-[40px] md:text-[50px] font-bold text-black py-[70px]">My Works</h2>
      <div className="flex flex-col max-w-[1344px] mx-auto px-[20px] lg:px-[70px]">
        <section className="w-full tab-container effectTab-header">
          <div className="max-w-full tab-item">
            <div className="flex flex-wrap w-full ">
              <div className="grid gap-4 lg:gap-10 grid-cols-1 xl:grid-cols-2  mx-auto">
                {worksInfo.map((info) => (
                  <WorksInfo {...info} key={info.id} />
                ))}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Works;